<template >
    
    <section id="portfolio" class="portfolio portfolio-details blog">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <p>Knowledge</p>
          <br>
            <span>
              Explore our library of knowledge products we produced on the sectors we work in
            </span>
          <br>
          
          <div class="col-lg-12 ">
            <div class="sidebar row">
              
             
              <div class="row">
                <h3 class="sidebar-title col-md-8" style="text-align: start;">Sectors</h3>
                <div class="sidebar-item search-form col-lg-4">
                  <form action="">
                    <input type="text" placeholder="Search">
                    <button class="select-search-bar" type="submit"><i class="bi bi-search"></i></button>
                  </form>
                </div>
              </div>
              
              <div class="sidebar-item categories col-md-12">

              
                  <ul id="portfolio-flters">
                    <li  class="filter-active">labour sourcing</li>
                    <li>access to finance</li>
                    <li>private sector development</li>
                    <li  >Manufacturing</li>
                    <li>Medium</li>
                    <li>small and micro enterprises</li>
                    <li  >access to finance</li>
                    <li>human development</li>
                    <li>private sector development</li>
                    <li  >Leather</li>
                    <li>horticulture</li>
                    <li>cotton</li>
                  </ul>
                
              </div>
              <div class="align-item-end-horizontal">
                <button class="select-search-bar button-pill" type="submit"><i class="bi bi-funnel"></i>Filter</button>
              </div>
              
              <!-- End sidebar search formn--><!-- End sidebar categories-->
            </div>

          </div>
        </header>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app" @click="remove">PDF</li>
              <li data-filter=".filter-card">Video</li>
            </ul>
          </div>
        </div>
<div id="list-complete-demo" class="demo">
  <button @click="shuffle">Shuffle</button>
  <button @click="add">Add</button>
  <button @click="remove">Remove</button>
  <transition-group name="list-complete" tag="p">
    
    <span v-for="item in items" :key="item" class="list-complete-item col-md-4 portfolio-item ">
      
        
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Cotton Textile and Apparel Sector Strategy</h4>
                <p class="colapsed-text-five">This document gives an overview of the cotton, textile and apparel sub-sectors in Ethiopia identifying the constraints and opportunities toward a development strategy.</p>

              </div>
            </div>
         
     
      
      
    </span>
  </transition-group>
</div>
      </div></section>


</template>
<script>
export default {
  data() {
    return {
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
    };
  },
  methods: {
    randomIndex() {
      return Math.floor(Math.random() * this.items.length);
    },
    add() {
      this.items.splice(this.randomIndex(), 0, this.nextNum++);
    },
    remove() {
      this.items = [1, 2, 3, 4];
    },
    shuffle() {
      this.items = _.shuffle(this.items);
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 30px;
}

button {
  margin-right: 10px;
}
.demo p {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-bottom: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.list-complete-item {
  transition: all 0.8s ease;
  // display: inline-block;
  margin-bottom: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>